import { Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setGameMode } from '../../gql';
import { isRegularMode } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import { REELS_AMOUNT, eventManager } from '../config';

import { DefaultSymbol, ScatterSymbol } from './slot';
import { CascadeAnimation } from './spin';

const baseGameOuterToInnerStepIndexes = [[0], [0, 1], [0, 1, 2], [0, 1, 2, 3]];

const freeSpinsOuterToInnerStepIndexes = [[0], [0, 1], [0, 1, 2], [0, 1, 2, 3], [0, 1, 2, 3, 4]];

export class Reel {
  public id: number;

  private symbols: SlotId[] = [];

  private slotSymbols: DefaultSymbol[] = [];

  public container: Container;

  public cascadeAnimation?: CascadeAnimation;

  constructor(id: number, symbols: SlotId[]) {
    this.id = id;
    this.container = new Container();
    this.container.angle = (360 / REELS_AMOUNT) * id;

    this.initSlots(symbols);
    this.startIdleAnimation();
  }

  private clearSlots(): void {
    this.container.removeChildren();
    this.slotSymbols = [];
  }

  private createSlots(symbols: SlotId[]) {
    symbols.forEach((symbol) => {
      const slotSymbol = symbol === SlotId.SC ? new ScatterSymbol(symbol) : new DefaultSymbol(symbol);
      this.slotSymbols.push(slotSymbol);
      this.container.addChild(slotSymbol.spine);
    });
  }

  public initSlots(symbols: SlotId[]) {
    this.symbols = symbols;
    this.clearSlots();
    this.createSlots(symbols);
  }

  public createSpinAnimation() {
    const disappearingAnimation = new AnimationChain({ isLoop: false });

    this.slotSymbols.forEach((_, i) => {
      const animationGroup = new AnimationGroup();
      const cascadeSymbols = this.slotSymbols.slice(0, this.slotSymbols.length - i);
      const disappearSymbol = this.slotSymbols[this.slotSymbols.length - i];
      animationGroup.addOnStart(() => {
        disappearSymbol?.setSymbolSkin('default');
      });
      cascadeSymbols.forEach((symbol, j) => {
        const index = i + j;
        const animation = symbol.getSpineAnimation(index, 'stop');
        animationGroup.addAnimation(animation);
      });
      disappearingAnimation.appendAnimation(animationGroup);
      disappearingAnimation.appendAnimation(Tween.createDelayAnimation(33));
    });

    disappearingAnimation.addOnComplete(() => {
      this.slotSymbols.forEach((slot) => slot.setSymbolSkin('default'));
    });

    this.cascadeAnimation = new CascadeAnimation({
      disappearingAnimation: disappearingAnimation,
      waitingAnimation: Tween.createDelayAnimation(15 * 1000),
    });

    return this.cascadeAnimation;
  }

  private createAppearingAnimation() {
    const appearingAnimation = new AnimationChain();

    // wait between reels
    appearingAnimation.appendAnimation(Tween.createDelayAnimation(this.id * 33));

    const cascadeStepSymbols: DefaultSymbol[][] = [];
    this.slotSymbols.forEach((_, i) => {
      cascadeStepSymbols.push(this.slotSymbols.slice(0, i + 1));
    });

    const animationStepIndexes = isRegularMode(setGameMode())
      ? baseGameOuterToInnerStepIndexes
      : freeSpinsOuterToInnerStepIndexes;

    cascadeStepSymbols.forEach((slots, i) => {
      const animationGroup = new AnimationGroup();
      slots.forEach((slot, j) => {
        const index = animationStepIndexes[i]![j]!;
        const animation = slot.getSpineAnimation(index, 'stop');
        animation.addOnStart(() => {
          if (!slot.spine.visible) slot.spine.visible = true;
          if (slot.slotId !== SlotId.SC) slot.setSymbolSkin();
        });
        animationGroup.addAnimation(animation);
      });
      appearingAnimation.appendAnimation(animationGroup);
      appearingAnimation.appendAnimation(Tween.createDelayAnimation(1));
    });

    return appearingAnimation;
  }

  public startSpinStopAnimation(symbols: SlotId[]) {
    this.initSlots(symbols);
    this.slotSymbols.forEach((slot) => (slot.spine.visible = false));

    const appearingAnimation = this.createAppearingAnimation();
    this.cascadeAnimation?.appendAnimation(appearingAnimation);

    const waitingAnimation = this.cascadeAnimation!.getWaiting();
    if (waitingAnimation.ended) {
      waitingAnimation.duration = 1;
      waitingAnimation.start();
    }
    if (this.id === REELS_AMOUNT - 1) {
      appearingAnimation.addOnStart(() => {
        AudioApi.play({ type: ISongs.SONG_032_12_Symbol_Stop });
      });
      appearingAnimation.addOnComplete(() => {
        eventManager.emit(EventTypes.REELS_STOPPED, false);
      });
    }

    waitingAnimation.end();
  }

  public startIdleAnimation() {
    //this.symbols = symbols;
    this.slotSymbols.forEach((slot, i) => {
      slot.slotId = this.symbols[i]!;
    });
    this.slotSymbols.forEach((slot, index) => slot.startStopAnimation(index));
  }

  public createWinAnimation(winSlotIndexes: number[]) {
    const animationGroup = new AnimationGroup();
    this.slotSymbols.forEach((slot, index) => {
      if (winSlotIndexes.includes(index)) {
        const win = slot.getSpineAnimation(index, 'win');
        animationGroup.addAnimation(win);
      }
    });

    return animationGroup;
  }

  public createLostAnimation(winSlotIndexes: number[]) {
    const animationGroup = new AnimationGroup();
    this.slotSymbols.forEach((slot, index) => {
      if (winSlotIndexes.includes(index)) {
        const win = slot.getSpineAnimation(index, 'lost');
        animationGroup.addAnimation(win);
      }
    });

    return animationGroup;
  }

  public createCascadeAnimation(remainingSymbols: SlotId[], fallSymbols: SlotId[]) {
    const animationGroup = new AnimationGroup();
    this.initSlots([...fallSymbols, ...remainingSymbols]);

    remainingSymbols.forEach((_slotId, index) => {
      const remainPosition = index + fallSymbols.length;
      const slot = this.slotSymbols[remainPosition]!;
      const stopAnimation = slot.getSpineAnimation(remainPosition, 'stop');
      animationGroup.addAnimation(stopAnimation);
    });

    fallSymbols.forEach((_slotId, index) => {
      const fallPosition = index;
      const slot = this.slotSymbols[fallPosition]!;
      const inAnimation = slot.getSpineAnimation(fallPosition, 'in');
      animationGroup.addAnimation(inAnimation);
    });

    return animationGroup;
  }
}
