import { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export const ISongs = {
  SONG_032_01_BaseGameBGM_Base: '032_01_BaseGameBGM_Base',
  SONG_032_02_BaseGameBGM_Melo: '032_02_BaseGameBGM_Melo',
  SONG_032_03_FreeSpinBGM: '032_03_FreeSpinBGM',
  SONG_032_04_TotalWinBanner_A: '032_04_TotalWinBanner_A',
  SONG_032_05_TotalWinBanner_B: '032_05_TotalWinBanner_B',
  SONG_032_06_BigWin_Loop: '032_06_BigWin_Loop',
  SONG_032_07_BigWin_End: '032_07_BigWin_End',
  SONG_032_08_CountUp_Loop: '032_08_CountUp_Loop',
  SONG_032_09_CountUp_End: '032_09_CountUp_End',
  SONG_032_10_Spider_roll: '032_10_Spider_roll',
  SONG_032_11_Spider_pulse: '032_11_Spider_pulse',
  SONG_032_12_Symbol_Stop: '032_12_Symbol_Stop',
  SONG_032_13_Symbol_Lockon: '032_13_Symbol_Lockon',
  SONG_032_14_Symbol_Win: '032_14_Symbol_Win',
  SONG_FeatureTrigger: 'FeatureTrigger',
  SONG_SFX_BuyFeature: 'SFX_BuyFeature',
  SONG_SFX_SM_CountUp_End: 'SFX_SM_CountUp_End',
  SONG_SFX_SM_CountUp_Loop: 'SFX_SM_CountUp_Loop',
  SONG_SFX_UI_AutoSpin: 'SFX_UI_AutoSpin',
  SONG_SFX_UI_BetChange: 'SFX_UI_BetChange',
  SONG_SFX_UI_Close: 'SFX_UI_Close',
  SONG_SFX_UI_General: 'SFX_UI_General',
  SONG_SFX_UI_Hover: 'SFX_UI_Hover',
  SONG_SFX_UI_MaxBet: 'SFX_UI_MaxBet',
  SONG_SFX_UI_MenuOpen: 'SFX_UI_MenuOpen',
  SONG_SFX_UI_SpinStart: 'SFX_UI_SpinStart',
  SONG_SFX_Win_Big: 'SFX_Win_Big',
  SONG_SFX_Win_Epic: 'SFX_Win_Epic',
  SONG_SFX_Win_Great: 'SFX_Win_Great',
  SONG_SFX_Win_Mega: 'SFX_Win_Mega',
  SONG_Yocho_Phoenix: 'Yocho_Phoenix',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ISongs = (typeof ISongs)[keyof typeof ISongs];

export const audioSprite: TAudioSprite = {
  '032_01_BaseGameBGM_Base': [0, 64480.00000000001, true],
  '032_02_BaseGameBGM_Melo': [66000, 64480.000000000015, true],
  '032_03_FreeSpinBGM': [132000, 65754.67120181407, true],
  '032_04_TotalWinBanner_A': [199000, 4934.67120181407],
  '032_05_TotalWinBanner_B': [205000, 4931.54195011337],
  '032_06_BigWin_Loop': [211000, 16055.986394557835, true],
  '032_07_BigWin_End': [229000, 2757.27891156464],
  '032_08_CountUp_Loop': [233000, 507.00680272109366, true],
  '032_09_CountUp_End': [235000, 1110.2267573696167],
  '032_10_Spider_roll': [238000, 1786.75736961452],
  '032_11_Spider_pulse': [241000, 1377.3469387755028],
  '032_12_Symbol_Stop': [244000, 509.6371882086146],
  '032_13_Symbol_Lockon': [246000, 681.337868480739],
  '032_14_Symbol_Win': [248000, 1092.7210884353826],
  'FeatureTrigger': [251000, 879.841269841279],
  'SFX_BuyFeature': [253000, 666.6666666666572],
  'SFX_SM_CountUp_End': [255000, 933.4693877551103],
  'SFX_SM_CountUp_Loop': [257000, 399.7505668934309, true],
  'SFX_UI_AutoSpin': [259000, 320.47619047619946],
  'SFX_UI_BetChange': [261000, 32.08616780045759],
  'SFX_UI_Close': [263000, 200.68027210885475],
  'SFX_UI_General': [265000, 88.34467120180989],
  'SFX_UI_Hover': [267000, 151.76870748297233],
  'SFX_UI_MaxBet': [269000, 122.92517006801518],
  'SFX_UI_MenuOpen': [271000, 277.1428571428487],
  'SFX_UI_SpinStart': [273000, 249.59183673468033],
  'SFX_Win_Big': [275000, 1622.3356009070358],
  'SFX_Win_Epic': [278000, 1862.2675736961583],
  'SFX_Win_Great': [281000, 2118.390022675726],
  'SFX_Win_Mega': [285000, 1696.1678004535088],
  'Yocho_Phoenix': [288000, 4033.33333333336],
};
