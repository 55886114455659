import { Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { Cascade, EventTypes, GameMode } from '../../global.d';
import { setGameMode, setScatterPositions } from '../../gql';
import { getSymbolMatrixFromSymbols, isFreeSpinMode } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import {
  BASE_GAME_SLOTS_PER_REEL_AMOUNT,
  FREE_SPINS_SLOTS_PER_REEL_AMOUNT,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  REELS_AMOUNT,
  eventManager,
} from '../config';

import { MultiplierSymbolReel } from './multiplierSymbol/multiplierSymbolReel';
import { Reel } from './reel';
import { ReelFrame } from './reelFrame';
import { Spider } from './spider';

export class ReelsContainer extends Container {
  private reelFrame: ReelFrame;

  private spider: Spider;

  public reels: Reel[] = [];

  public multiplierSymbolReels: MultiplierSymbolReel[] = [];

  constructor(symbolMatrix: SlotId[][]) {
    super();
    this.position.set(GAME_CONTAINER_WIDTH / 2, GAME_CONTAINER_HEIGHT / 2);

    this.initReels(symbolMatrix);

    this.reelFrame = new ReelFrame(this);

    this.initMultiplierSymbolReels();

    this.spider = new Spider(this);

    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.showStopSlots.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupReelPosition.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.START_CASCADE_ANIMATION, this.onStartCascadeAnimation.bind(this));
  }

  private initReels(symbolMatrix: SlotId[][]) {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = new Reel(i, symbolMatrix[i]!);
      this.reels[i] = reel;
      this.addChild(reel.container);
    }
  }

  private initMultiplierSymbolReels() {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const mpSymbolReel = new MultiplierSymbolReel(i);
      this.multiplierSymbolReels[i] = mpSymbolReel;
      this.addChild(mpSymbolReel.container);
    }
  }

  private setupReelPosition(symbolMatrix: SlotId[][], _scatterStopCount: number[], multiplierMatrix?: number[][]) {
    this.reels.forEach((reel, i) => {
      reel.startSpinStopAnimation(symbolMatrix[i]!);
    });

    if (multiplierMatrix) {
      this.multiplierSymbolReels.forEach((reel, reelId) => {
        reel.startSpinStopAnimation(multiplierMatrix[reelId]!);
      });
    }
  }

  private onStartSpinAnimation() {}

  // TODO Separate each object later
  private onStartWinAnimation(cascade: Cascade, cascadeStep: number, cascades: Cascade[]) {
    const scatterPositions = cascadeStep === 0 && setScatterPositions().length ? setScatterPositions() : [];
    let winPositions = [...new Set(cascade.winPositions.flatMap((positions) => positions))];
    if (scatterPositions.length && cascadeStep === 0) {
      winPositions = winPositions.filter((v) => !scatterPositions.includes(v));
    }

    const animationChain = new AnimationChain();
    const winAnimations = new AnimationGroup();
    const lostAnimations = new AnimationGroup();

    for (let reelId = 0; reelId < REELS_AMOUNT; reelId++) {
      const reel = this.reels[reelId]!;
      const lucySymbolReel = this.multiplierSymbolReels[reelId]!;
      const reelWinPositions: number[] = [];
      for (let slotIndex = 0; slotIndex < this.getSlotPerAmount(setGameMode()); slotIndex++) {
        const position = slotIndex * REELS_AMOUNT + reelId;
        if (winPositions.includes(position)) {
          reelWinPositions.push(position);
        }
      }

      if (reelWinPositions.length > 0) {
        const pos = reelWinPositions.map((pos) => Math.floor(pos / REELS_AMOUNT));

        const win = reel.createWinAnimation(pos);
        winAnimations.addAnimation(win);

        const lost = reel.createLostAnimation(pos);
        lostAnimations.addAnimation(lost);

        const lsWin = lucySymbolReel.createWinAnimation(pos);
        winAnimations.addAnimation(lsWin);

        const lsLost = lucySymbolReel.createLostAnimation(pos);
        lostAnimations.addAnimation(lsLost);

        winAnimations.addOnStart(() => {
          AudioApi.play({ type: ISongs.SONG_032_13_Symbol_Lockon });
        });

        lostAnimations.addOnStart(() => {
          AudioApi.stop({ type: ISongs.SONG_032_13_Symbol_Lockon });
          AudioApi.play({ type: ISongs.SONG_032_14_Symbol_Win });
        });
      }

      if (scatterPositions.length && cascadeStep === 0) {
        const reelScatterPositions: number[] = [];
        for (let slotIndex = 0; slotIndex < this.getSlotPerAmount(setGameMode()); slotIndex++) {
          const position = slotIndex * REELS_AMOUNT + reelId;
          if (scatterPositions.includes(position)) {
            reelScatterPositions.push(position);
          }
        }
        if (reelScatterPositions.length) {
          const scatterWin = reel.createWinAnimation(reelScatterPositions.map((pos) => Math.floor(pos / REELS_AMOUNT)));
          winAnimations.addAnimation(scatterWin);
        }
      }
    }

    const spiderAttack = this.spider.getSpineAnimation(setGameMode(), 'attack');
    spiderAttack.addOnStart(() => {
      this.spider.clearIdleAnimation();
      AudioApi.play({ type: ISongs.SONG_032_10_Spider_roll });
    });
    animationChain.appendAnimation(spiderAttack);

    winAnimations.addOnStart(() => {
      this.spider.clearIdleAnimation();
      this.spider.getSpineAnimation(setGameMode(), 'win').start();
      this.reelFrame.startWinAnimation(setGameMode());
      const delay = Tween.createDelayAnimation(1200);
      delay.addOnComplete(() => {
        AudioApi.play({ type: ISongs.SONG_032_11_Spider_pulse });
        eventManager.emit(EventTypes.START_SPIDER_WIN_ANIMATION);
      });
      delay.start();

      if (cascadeStep === 0) {
        const winAmounts = cascade.amounts.reduce((sum, v) => sum + v, 0);
        eventManager.emit(EventTypes.START_WIN_COUNT_UP_MESSAGE, 0, winAmounts);
      } else {
        const prevWinAmounts = cascades
          .map((cascade) => cascade.amounts)
          .reduce((sum, amounts, index) => {
            if (index >= cascadeStep) {
              return sum;
            }
            return sum + amounts.reduce((s, v) => s + v);
          }, 0);
        const winAmounts = cascades[cascadeStep]!.amounts.reduce((sum, v) => sum + v);
        eventManager.emit(EventTypes.START_WIN_COUNT_UP_MESSAGE, prevWinAmounts, prevWinAmounts + winAmounts);
      }
    });
    animationChain.appendAnimation(winAnimations);
    animationChain.appendAnimation(lostAnimations);

    animationChain.addOnComplete(() => {
      console.log('reelContainer->onStartWinAnimation->complete');
      eventManager.emit(EventTypes.NEXT_CASCADE, cascadeStep + 1);
    });

    console.log('reelContainer->onStartWinAnimation->start');

    animationChain.start();
  }

  private onStartCascadeAnimation() {}

  public getSlotPerAmount(gameMode: GameMode) {
    if (isFreeSpinMode(gameMode)) {
      return FREE_SPINS_SLOTS_PER_REEL_AMOUNT;
    }

    return BASE_GAME_SLOTS_PER_REEL_AMOUNT;
  }

  private showStopSlots(spinResult: SlotId[]) {
    const symbolMatrix = getSymbolMatrixFromSymbols<SlotId>(spinResult);
    this.reels.forEach((reel, reelId) => {
      for (let i = 0; i < this.getSlotPerAmount(setGameMode()); i++) {
        const symbols = symbolMatrix[reelId]!;
        reel.initSlots(symbols);
        reel.startIdleAnimation();
      }
    });
  }

  private onChangeMode(_settings: { mode: GameMode }) {
    this.multiplierSymbolReels.forEach((v) => v.clear());
  }
}
