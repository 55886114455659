import * as PIXI from 'pixi.js';

import { Colors } from '../../config';
import { MessageFreeSpinsBannerProps } from '../../global';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import { TextField } from '../components/TextField';
import AutoResizeText from '../text/autoResizeText';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  MESSAGE_FREE_SPINS_BANNER_BUTTON_Y,
  MESSAGE_FREE_SPINS_BANNER_DESCRIPTIONS1_Y,
  MESSAGE_FREE_SPINS_BANNER_DESCRIPTION_Y,
  MESSAGE_FREE_SPINS_BANNER_TITLE_Y,
  MESSAGE_FREE_SPINS_BANNER_Y,
} from './config';
import { btnTextStyle, descriptionsTextStyle, titleTextStyle } from './textStyles';

export class MessageFreeSpinsBanner extends BaseMessageBanner {
  protected override props: MessageFreeSpinsBannerProps;

  protected banner?: PIXI.Sprite;

  private title?: AutoResizeText;

  private description?: AutoResizeText;

  private descriptions: TextField[] = [];

  private btn?: AutoResizeText;

  constructor(props: MessageFreeSpinsBannerProps) {
    super(props);
    this.props = props;
    this.y = MESSAGE_FREE_SPINS_BANNER_Y;
  }

  public override init(): MessageFreeSpinsBanner {
    super.init();
    this.banner = this.initBanner();
    this.title = this.initTitle(this.props.title);
    this.description = this.initDescription(this.props.description);
    this.descriptions = this.initDescriptions();
    this.btn = this.initBtn(this.props.btnText);
    this.addChild(this.banner, this.title, this.description, this.btn);
    this.addChild(...this.descriptions.map((v) => v.getText()));
    return this;
  }

  protected initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messagebannerA));
    banner.anchor.set(0.5, 0);
    return banner;
  }

  private initTitle(titleText: string): AutoResizeText {
    const title = new AutoResizeText(titleText, titleTextStyle);
    title.anchor.set(0.5, 0.5);
    title.y = MESSAGE_FREE_SPINS_BANNER_TITLE_Y;
    updateTextScale(title, this.banner!.width - 250, 250);
    return title;
  }

  private initDescription(descriptionText: string): AutoResizeText {
    const description = new AutoResizeText(descriptionText, titleTextStyle);
    description.anchor.set(0.5, 0.5);
    description.y = MESSAGE_FREE_SPINS_BANNER_DESCRIPTION_Y;
    updateTextScale(description, this.banner!.width - 250, 250);
    return description;
  }

  private initDescriptions(): TextField[] {
    const descriptions = [];
    // TODO TextField use for span
    const description1 = new TextField(i18n.t('freeSpinsDesc_1'), this.banner!.width - 250, 250, {}, true, {
      default: { ...descriptionsTextStyle },
      span: { fill: Colors.GAME_COLOR, lineHeight: 50 },
    });
    description1.text.anchor.set(0.5, 0.5);
    description1.text.position.y = MESSAGE_FREE_SPINS_BANNER_DESCRIPTIONS1_Y;
    descriptions.push(description1);
    return descriptions;
  }

  private initBtn(btnText: string): AutoResizeText {
    const btn = new AutoResizeText(btnText, btnTextStyle);
    btn.anchor.set(0.5, 0);
    btn.y = MESSAGE_FREE_SPINS_BANNER_BUTTON_Y;
    updateTextScale(btn, this.banner!.width - 250, 250);
    return btn;
  }
}
