import { AnimatedSprite, Container } from 'pixi.js';

import Tween from '../tween';

import { Charset } from './charSet';
import { IMatrixRainAnimationOptions, getRandomFromRange } from './matrixRainAnimation';

export class RainCharsColumn extends Container {
  private animatedSprites: AnimatedSprite[] = [];

  constructor(x = 0, textures = Charset.getTextures(), options: IMatrixRainAnimationOptions) {
    super();

    let headIndex = 0;

    this.init(x, textures, options.maxLength, options.tint);

    const decayRate = options.growRate / (options.maxLength * options.fadeRate);

    const getPlayCount = () => {
      const count = options.minPlayCount + Math.floor(Math.random() * options.maxPlayCount);
      return count;
    };

    {
      const initFadeTimer = () => {
        Tween.setConditionalLoop(onFadeTimer, options.growRate);
      };

      const onFadeTimer = () => {
        if (!this.animatedSprites.length) return false;

        eachSpriteDo(this.animatedSprites);
        return true;
      };

      const eachSpriteDo = (animatedSprites: AnimatedSprite[] = []) => {
        animatedSprites.forEach((sprite) => {
          fadeSprite(sprite);
        });
      };

      const fadeSprite = (animatedSprite: AnimatedSprite) => {
        if (animatedSprite.visible) {
          animatedSprite.alpha -= decayRate;
          playAnimation(animatedSprite);
          if (animatedSprite.alpha <= 0) {
            animatedSprite.visible = false;
            animatedSprite.alpha = 1;
          }
        }
      };

      const playAnimation = (animatedSprite: AnimatedSprite) => {
        if (Math.random() > 1 - options.chanceToRevertChar) {
          playFrames(animatedSprite, getPlayCount());
        }
      };

      const playFrames = (animatedSprite: AnimatedSprite, playCount = 0, timeout = 120) => {
        if (playCount > 0) {
          Tween.setConditionalLoop(() => {
            animatedSprite.gotoAndStop(animatedSprite.currentFrame + 1);
            playCount--;
            playFrames(animatedSprite, playCount, timeout);
            return false;
          }, timeout);
        }
      };

      initFadeTimer();
    }

    {
      const initCoolDownTimer = () => {
        Tween.setConditionalLoop(initGrowTimer, getRandomFromRange(options.coolDownRange));
      };

      const initGrowTimer = () => {
        headIndex = 0;
        Tween.setConditionalLoop(onGrowTimer, options.growRate);
      };

      const onGrowTimer = () => {
        if (!this.animatedSprites.length) return false;

        highlight();
        if (headIndex === options.maxLength) {
          initCoolDownTimer();
        }
        return headIndex < options.maxLength;
      };

      const highlight = (color = options.tint, colorHighlight = options.highlight) => {
        const prevSprite = this.animatedSprites[headIndex - 1]!;
        if (prevSprite) prevSprite.tint = color;

        const currentSprite = this.animatedSprites[headIndex]!;
        if (currentSprite) {
          currentSprite.tint = colorHighlight;
          currentSprite.visible = true;
          headIndex++;
        }
      };

      initCoolDownTimer();
    }
  }

  private init(x = 0, textures = Charset.getTextures(), length: number, color: number) {
    for (let i = 0; i <= length; i++) {
      const animatedSprite = new AnimatedSprite(textures);
      animatedSprite.y = i * animatedSprite.height - 100;
      animatedSprite.tint = color;
      animatedSprite.visible = false;
      animatedSprite.gotoAndStop(Math.floor(Math.random() * animatedSprite.totalFrames));
      this.addChild(animatedSprite);
      this.animatedSprites.push(animatedSprite);
    }
    this.x = x;
  }

  public clear() {
    this.removeChildren();
    this.animatedSprites = [];
  }
}
