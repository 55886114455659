import { setUserConfig } from '@phoenix7dev/utils-fe';

import { GameMode, PopupOpeningTypes, UserBonus } from '../global.d';
import { isMobileDevice } from '../utils';

import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameHistory,
  setGameMode,
  setIsAuthorized,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsFadeOut,
  setIsFreeSpinsWin,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpeningInProgress,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSoundOn,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsSuspended,
  setIsTurboSpin,
  setProgress,
  setReelSetId,
  setSlotConfig,
  setSoundValue,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setStressful,
  setWinAmount,
} from './cache';
import { DataGQl, IConfig, ISlotConfig, IStressful } from './d';

const typePolicies = {
  Query: {
    fields: {
      isAuthorized: {
        read(): boolean {
          return setIsAuthorized();
        },
      },
      progress: {
        read(): { status: number; wasLoaded?: boolean } {
          return setProgress();
        },
      },
      isSoundOn: {
        read(_: unknown, { storeFieldName }: DataGQl): boolean {
          return setUserConfig<IConfig>('config', storeFieldName, setIsSoundOn());
        },
      },
      isShowSoundToast: {
        read(): boolean {
          return setIsShowSoundToast();
        },
      },
      isShowSuspendedToast: {
        read(): boolean {
          return setIsSuspended();
        },
      },
      soundValue: {
        read(): number {
          return setSoundValue();
        },
      },
      isSpinInProgress: {
        read(): boolean {
          return setIsSpinInProgress();
        },
      },
      isFreeSpinsWin: {
        read(): boolean {
          return setIsFreeSpinsWin();
        },
      },
      isSlotBusy: {
        read(): boolean {
          return setIsSlotBusy();
        },
      },
      isMobile: {
        read(): boolean {
          return isMobileDevice();
        },
      },
      betAmount: {
        read(): number {
          return setBetAmount();
        },
      },
      currentFreeSpinsTotalWin: {
        read(): number {
          return setCurrentFreeSpinsTotalWin();
        },
      },
      winAmount: {
        read(): number {
          return setWinAmount();
        },
      },
      coinValue: {
        read(): number {
          return setCoinValue();
        },
      },
      coinAmount: {
        read(): number {
          return setCoinAmount();
        },
      },
      autoSpinsAmount: {
        read(): number {
          return setAutoSpinsAmount();
        },
      },
      isContinueAutoSpinsAfterFeature: {
        read(): boolean {
          return setIsContinueAutoSpinsAfterFeature();
        },
      },
      isStopOnAnyWin: {
        read(): boolean {
          return setIsStopOnAnyWin();
        },
      },
      isStopOnFeatureWin: {
        read(): boolean {
          return setIsStopOnFeatureWin();
        },
      },
      isStopOnWinExceeds: {
        read(): boolean {
          return setIsStopOnWinExceeds();
        },
      },
      isStopOnBalanceIncrease: {
        read(): boolean {
          return setIsStopOnBalanceIncrease();
        },
      },
      isStopOnBalanceDecrease: {
        read(): boolean {
          return setIsStopOnBalanceDecrease();
        },
      },
      isAutoSpins: {
        read(): boolean {
          return setIsAutoSpins();
        },
      },
      freeSpinsTotalWin: {
        read(): number {
          return setFreeSpinsTotalWin();
        },
      },
      gameMode: {
        read(): GameMode {
          return setGameMode();
        },
      },
      reelSetId: {
        read(): string {
          return setReelSetId();
        },
      },
      currentBonusId: {
        read(): string {
          return setCurrentBonusId();
        },
      },
      currentBonus: {
        read(): UserBonus {
          return setCurrentBonus();
        },
      },
      autoSpinsLeft: {
        read(): number {
          return setAutoSpinsLeft();
        },
      },
      stopOnWinExceeds: {
        read(): number {
          return setStopOnWinExceeds();
        },
      },
      stopOnBalanceIncrease: {
        read(): number {
          return setStopOnBalanceIncrease();
        },
      },
      stopOnBalanceDecrease: {
        read(): number {
          return setStopOnBalanceDecrease();
        },
      },
      autoSpinsStartBalance: {
        read(): number {
          return setAutoSpinsStartBalance();
        },
      },
      slotConfig: {
        read(): ISlotConfig {
          return setSlotConfig();
        },
      },
      slotHistory: {
        read(): boolean[] {
          return setGameHistory();
        },
      },
      isTurboSpin: {
        read(_: unknown, { storeFieldName }: DataGQl): boolean {
          return setUserConfig<IConfig>('config', storeFieldName, setIsTurboSpin());
        },
      },
      stressful: {
        read(): IStressful {
          return setStressful();
        },
      },
      bets: {
        keyArgs: false as const,
        merge: false,
      },
      isSoundLoading: {
        read(): boolean {
          return setIsSoundLoading();
        },
      },
      isOpenBetSettingsPopup: {
        read(): boolean {
          return setIsOpenBetSettingsPopup();
        },
      },
      isOpenAutoplayPopup: {
        read(): boolean {
          return setIsOpenAutoplayPopup();
        },
      },
      isOpenInfoPopup: {
        read(): boolean {
          return setIsOpenInfoPopup();
        },
      },
      isOpenHistoryPopup: {
        read(): boolean {
          return setIsOpenHistoryPopup();
        },
      },
      isPopupOpeningInProgress: {
        read(): PopupOpeningTypes {
          return setIsPopupOpeningInProgress();
        },
      },
      isFadeOut: {
        read(): boolean {
          return setIsFadeOut();
        },
      },
    },
  },
};

export default typePolicies;
