import { ShockwaveFilter } from 'pixi-filters';
import { Application, DisplayObject } from 'pixi.js';

import { SpineFile } from '../config/spine.generated';
import { Game } from '../game';
import Animator from '../slotMachine/animations/animator';
import { StrictSpine } from '../slotMachine/components/spine';
import { Easing } from '../slotMachine/devTool/easing';

export class Maker {
  private readonly app: Application;

  constructor(app: Application) {
    this.app = app;
  }

  public spine<SF extends SpineFile = SpineFile>(key: SF): StrictSpine {
    const spine = new StrictSpine(key, Animator.ticker);
    if (!spine) {
      throw new Error(`Spine assets with key ${key} not found`);
    }

    return spine;
  }

  // TODO fix later
  public shockWave(
    displayObject: DisplayObject,
    x = 0,
    y = 0,
    shockWaveOptions = { amplitude: 30, wavelength: 160, brightness: 1 }, //{ amplitude: 70, wavelength: 100, brightness: 2 },
    animationTime = 1000,
    easing = 'Liner',
  ) {
    const shockWave = new ShockwaveFilter([x, y], shockWaveOptions);
    displayObject.filters = [shockWave];

    const totalTime = 2;
    let time = 0;
    const animate = (): void => {
      time += Game.getInstance().app.ticker.deltaMS;
      const p = time / animationTime;
      const progress = Easing.get(easing)(p);
      shockWave.time = progress * totalTime;
      if (p >= 1) {
        Game.getInstance().app.ticker.remove(animate);
        displayObject.filters = displayObject.filters!.filter((filter) => filter !== shockWave);
      }
    };
    Game.getInstance().app.ticker.add(animate);
  }
}
