import { Container, Text } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { SpineInterface } from '../../config/spine.generated';
import { Game } from '../../game';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCurrency, setIsDuringBigWinLoop, setIsDuringWinCountUpAnimation } from '../../gql';
import { getWinStage, normalizeCoins, showCurrency } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { BgmControl } from '../bgmControl/bgmControl';
import { BigWinStages, WinStages, bigWinValueStyles, eventManager } from '../config';

import {
  BIG_WIN_JINGLE_DURATION,
  WIN_LABEL_X,
  WIN_LABEL_Y,
  WIN_TITLE_X,
  WIN_TITLE_Y,
  bigWinAnimationNames,
  bigWinsConfig,
} from './config';

export default class BigWinsPresentation extends Container {
  private winValue = new Text();

  private animation: Animation | null = null;

  private spine = Game.getInstance().maker.spine('win');

  constructor() {
    super();
    this.addChild(this.winValue);
    this.addChild(this.spine);

    eventManager.addListener(EventTypes.START_BIG_WIN_PRESENTATION, this.startBigWinsPresentation.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
  }

  private createBigWinAnimation(stage: BigWinStages, win: number, bet: number, isLast: boolean) {
    const { beginMultiplier, limitAmount, duration, song } = bigWinsConfig[stage];
    const labelAnimation = this.createBigWinLabelAnimation(stage);

    const countUpAnimationGroup = new AnimationGroup();
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * beginMultiplier,
      target: Math.min(win, bet * limitAmount),
      object: this.winValue,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      BgmControl.fadeOutAll(1000);
      AudioApi.play({ type: song, stopPrev: true });
      labelAnimation.start();
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * limitAmount));
      AudioApi.stop({ type: bigWinsConfig[stage].song });
    });

    countUpAnimation.addOnComplete(() => {
      this.setWinValue(Math.min(win, bet * limitAmount));
      AudioApi.stop({ type: bigWinsConfig[stage].song });
      if (isLast) {
        this.createBigWinLabelOutAnimation(stage).start();
      }
    });

    countUpAnimationGroup.addAnimation(countUpAnimation);

    if (!isLast) {
      const outLabelAnimation = this.createBigWinLabelOutAnimation(stage);
      const delayDuration = countUpAnimation.duration - outLabelAnimation.duration;
      const animation = Tween.createDelayAnimation(delayDuration);
      animation.addOnComplete(() => {
        outLabelAnimation.start();
      });
      animation.addOnSkip(() => {
        outLabelAnimation.skip();
      });
      countUpAnimationGroup.addAnimation(animation);
    }

    return countUpAnimationGroup;
  }

  private startBigWinsPresentation(winCoinAmount: number) {
    this.setWinValue(0);
    this.winValue.visible = true;
    this.winValue.alpha = 1;

    const betAmount = normalizeCoins(setBetAmount());
    const winAmount = normalizeCoins(winCoinAmount);
    const currentStage = getWinStage(winCoinAmount);

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });
    animationChain.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_COINS);
    });
    animationChain.addOnSkip(() => {
      eventManager.emit(EventTypes.HIDE_COINS);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
      eventManager.emit(EventTypes.END_BIG_WIN_PRESENTATION);
      this.clean();
    });
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_COINS);
      eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
      eventManager.emit(EventTypes.END_BIG_WIN_PRESENTATION);
      this.clean();
    });
    setIsDuringBigWinLoop(true);
    setIsDuringWinCountUpAnimation(true);

    for (let stage = WinStages.BigWin; stage <= currentStage; stage++) {
      const bigWinAnimation = this.createBigWinAnimation(
        stage as BigWinStages,
        winAmount,
        betAmount,
        currentStage === stage,
      );
      animationChain.appendAnimation(bigWinAnimation);
    }

    AudioApi.play({ type: ISongs.SONG_032_06_BigWin_Loop, stopPrev: true });

    const playBigWinEndJingle = Tween.createDelayAnimation(BIG_WIN_JINGLE_DURATION);
    playBigWinEndJingle.addOnStart(() => {
      AudioApi.stop({ type: ISongs.SONG_032_06_BigWin_Loop });
      AudioApi.play({ type: ISongs.SONG_032_07_BigWin_End });
    });
    animationChain.appendAnimation(playBigWinEndJingle);

    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this.winValue,
      easing: (n) => Math.pow(n, 8),
      property: TweenProperties.ALPHA,
      duration: 1000,
    });

    fadeOutAnimation.addOnStart(() => {
      eventManager.emit(EventTypes.HANDLE_START_FADE_ANIMATION, currentStage);
    });
    fadeOutAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
    });
    animationChain.appendAnimation(fadeOutAnimation);

    this.animation = animationChain;
    animationChain.start();
  }

  private createBigWinLabelAnimation(stage: BigWinStages) {
    const animation = new AnimationChain();
    const spine = this.spine;
    const inAnimation = spine.getAnimation(0, bigWinAnimationNames[stage].in as SpineInterface['win']['animations']);
    const loopAnimation = spine.getAnimation(
      0,
      bigWinAnimationNames[stage].loop as SpineInterface['win']['animations'],
    );
    loopAnimation.isLoop = true;

    spine.position.set(WIN_LABEL_X, WIN_LABEL_Y - 200);
    //this.addChild(spine);

    animation.appendAnimation(inAnimation);
    animation.appendAnimation(loopAnimation);

    animation.addOnStart(() => {
      this.spine.visible = true;
    });

    animation.addOnSkip(() => {
      this.spine.visible = false;
    });

    return animation;
  }
  private createBigWinLabelOutAnimation(stage: BigWinStages) {
    const spine = this.spine;
    const animation = spine.getAnimation(0, bigWinAnimationNames[stage].out as SpineInterface['win']['animations']);

    spine.position.set(WIN_LABEL_X, WIN_LABEL_Y - 200);

    const cleanup = () => {
      this.spine.visible = false;
    };
    animation.addOnSkip(() => cleanup());
    animation.addOnComplete(() => cleanup());

    return animation;
  }

  private skipWinCountUpAnimation() {
    this.animation?.skip();
  }

  public setWinValue(winValue: number) {
    this.winValue.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
    this.winValue.style = bigWinValueStyles;
    this.winValue.anchor.set(0.5, 0.5);
    this.winValue.position.set(WIN_TITLE_X, WIN_TITLE_Y);
  }

  public clean() {
    this.winValue.visible = false;
    this.setWinValue(0);

    this.spine.visible = false;
    this.spine.state.setEmptyAnimation(0, 0);

    this.animation = null;

    setIsDuringWinCountUpAnimation(false);
    setIsDuringBigWinLoop(false);
    AudioApi.stop({ type: ISongs.SONG_032_06_BigWin_Loop });
    AudioApi.stop({ type: ISongs.SONG_032_07_BigWin_End });
    AudioApi.stop({ type: ISongs.SONG_SFX_Win_Epic });
    AudioApi.stop({ type: ISongs.SONG_SFX_Win_Great });
    AudioApi.stop({ type: ISongs.SONG_SFX_Win_Mega });
    AudioApi.stop({ type: ISongs.SONG_SFX_Win_Big });
    BgmControl.fadeInBase(3000);
  }
}
