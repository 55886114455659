import { Container } from 'pixi.js';

import { SlotId } from '../../../config';
import AnimationChain from '../../animations/animationChain';
import AnimationGroup from '../../animations/animationGroup';
import Tween from '../../animations/tween';
import { REELS_AMOUNT } from '../../config';
import { CascadeAnimation } from '../spin';

import { MultiplierSymbol } from './multiplierSymbol';

const freeSpinsOuterToInnerStepIndexes = [[0], [0, 1], [0, 1, 2], [0, 1, 2, 3], [0, 1, 2, 3, 4]];

export class MultiplierSymbolReel {
  private id: number;

  public multiPlierSymbols: MultiplierSymbol[] = [];

  public cascadeAnimation?: CascadeAnimation;

  public container = new Container();

  constructor(id: number, multipliers?: number[]) {
    this.id = id;
    this.container.angle = (360 / REELS_AMOUNT) * id;

    if (multipliers) {
      this.init(multipliers);
    }
  }

  public clear() {
    this.container.removeChildren();
    this.multiPlierSymbols = [];
  }

  private init(multipliers: number[]) {
    this.clear();
    multipliers.forEach((v, i) => {
      const mpSymbol = new MultiplierSymbol(v, this.id, i);
      this.container.addChild(mpSymbol);
      this.multiPlierSymbols.push(mpSymbol);
    });
  }

  public createSpinAnimation() {
    const disappearingAnimation = new AnimationChain({ isLoop: false });

    disappearingAnimation.addOnStart(() => {
      this.multiPlierSymbols.forEach((v) => {
        if (v.multiplier > 0) v.visible = true;
      });
    });

    const cascadeStepSymbols: MultiplierSymbol[][] = [];
    this.multiPlierSymbols.forEach((_, i) => {
      cascadeStepSymbols.push(this.multiPlierSymbols.slice(i, this.multiPlierSymbols.length));
    });

    cascadeStepSymbols.forEach((symbols) => {
      const animationGroup = new AnimationGroup();
      symbols.forEach((symbol, i) => {
        const animation = symbol.getSpineAnimation(i, 'stop');
        animation.addOnStart(() => {
          symbol.changePosition(i);
        });
        animationGroup.addAnimation(animation);
      });
      disappearingAnimation.appendAnimation(animationGroup);
      disappearingAnimation.appendAnimation(Tween.createDelayAnimation(1));
    });

    disappearingAnimation.addOnComplete(() => {
      this.multiPlierSymbols.forEach((v) => (v.visible = false));
    });

    this.cascadeAnimation = new CascadeAnimation({
      disappearingAnimation: disappearingAnimation,
      waitingAnimation: Tween.createDelayAnimation(15 * 1000),
    });

    return this.cascadeAnimation;
  }

  private createAppearingAnimation() {
    const appearingAnimation = new AnimationChain();

    // wait between reels
    appearingAnimation.appendAnimation(Tween.createDelayAnimation(this.id * 33));

    const cascadeStepSymbols: MultiplierSymbol[][] = [];
    this.multiPlierSymbols.forEach((_, i) => {
      cascadeStepSymbols.push(this.multiPlierSymbols.slice(0, i + 1));
    });

    cascadeStepSymbols.forEach((symbols, i) => {
      const animationGroup = new AnimationGroup();
      symbols.forEach((symbol, j) => {
        const index = freeSpinsOuterToInnerStepIndexes[i]![j]!;
        const animation = symbol.getSpineAnimation(index, 'stop');
        animation.addOnStart(() => {
          if (symbol.multiplier > 0) {
            symbol.changePosition(index);
            symbol.visible = true;
          } else {
            symbol.visible = false;
          }
        });
        animationGroup.addAnimation(animation);
      });
      appearingAnimation.appendAnimation(animationGroup);
      appearingAnimation.appendAnimation(Tween.createDelayAnimation(1));
    });

    return appearingAnimation;
  }

  public startSpinStopAnimation(multipliers: number[]) {
    this.init(multipliers);
    this.multiPlierSymbols.forEach((v) => (v.visible = false));

    const appearingAnimation = this.createAppearingAnimation();
    this.cascadeAnimation?.appendAnimation(appearingAnimation);

    const waitingAnimation = this.cascadeAnimation!.getWaiting();
    if (waitingAnimation.ended) {
      waitingAnimation.duration = 1;
      waitingAnimation.start();
    }

    waitingAnimation.end();
  }

  public createWinAnimation(winSlotIndexes: number[]) {
    const animationGroup = new AnimationGroup();
    this.multiPlierSymbols.forEach((symbol, index) => {
      if (winSlotIndexes.includes(index)) {
        if (symbol.multiplier > 0) {
          const win = symbol.getSpineAnimation(index, 'win');
          animationGroup.addAnimation(win);
        }
      }
    });

    return animationGroup;
  }

  public createLostAnimation(winSlotIndexes: number[]) {
    const animationGroup = new AnimationGroup();
    this.multiPlierSymbols.forEach((symbol, index) => {
      if (winSlotIndexes.includes(index)) {
        if (symbol.multiplier > 0) {
          const lost = symbol.getSpineAnimation(index, 'lost');
          lost.addOnStart(() => {
            Tween.setTimeout(() => {
              symbol.multiplier = 0;
              symbol.visible = false;
            }, 500);
          });
          animationGroup.addAnimation(lost);
        }
      }
    });

    return animationGroup;
  }

  public createCascadeAnimation(remainingSymbols: SlotId[], fallSymbols: SlotId[], multipliers: number[]) {
    const animationGroup = new AnimationGroup();
    this.init(multipliers);

    remainingSymbols.forEach((_slotId, index) => {
      const remainPosition = index + fallSymbols.length;
      const mpSymbol = this.multiPlierSymbols[remainPosition]!;
      if (multipliers[remainPosition]! > 0) {
        const stopAnimation = mpSymbol.getSpineAnimation(remainPosition, 'stop');
        stopAnimation.addOnStart(() => {
          mpSymbol.changePosition(remainPosition);
        });
        animationGroup.addAnimation(stopAnimation);
      } else {
        mpSymbol.visible = false;
      }
    });

    /* remainingSymbols.forEach((_, index) => {
      const mpSymbol = this.multiPlierSymbols[index]!;
      if (multipliers[index]! > 0) {
        const stopAnimation = mpSymbol.getSpineAnimation(index, 'stop');
        stopAnimation.addOnStart(() => {
          mpSymbol.changePosition(index);
        });
        animationGroup.addAnimation(stopAnimation);
      } else {
        mpSymbol.visible = false;
      }
    }); */
    fallSymbols.forEach((_slotId, index) => {
      const fallPosition = index;
      const mpSymbol = this.multiPlierSymbols[fallPosition]!;
      if (multipliers[fallPosition]! > 0) {
        const inAnimation = mpSymbol.getSpineAnimation(fallPosition, 'in');
        animationGroup.addAnimation(inAnimation);
      } else {
        mpSymbol.visible = false;
      }
    });
    /* fallSymbols.forEach((_, index) => {
      const fallPosition = index + remainingSymbols.length;
      const mpSymbol = this.multiPlierSymbols[fallPosition]!;
      if (multipliers[fallPosition]! > 0) {
        const inAnimation = mpSymbol.getSpineAnimation(fallPosition, 'in');
        inAnimation.addOnStart(() => {
          mpSymbol.changePosition(fallPosition);
        });
        animationGroup.addAnimation(inAnimation);
      } else {
        mpSymbol.visible = false;
      }
    }); */

    return animationGroup;
  }
}
