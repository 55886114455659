export const easingProperties = {
  Linear: 'Linear',
  InSine: 'InSine',
  OutSine: 'OutSine',
  InOutSine: 'InOutSine',
  InQuad: 'InQuad',
  OutQuad: 'OutQuad',
  InOutQuad: 'InOutQuad',
  InCubic: 'InCubic',
  OutCubic: 'OutCubic',
  InOutCubic: 'InOutCubic',
  InQuart: 'InQuart',
  OutQuart: 'OutQuart',
  InOutQuart: 'InOutQuart',
  InQuint: 'InQuint',
  OutQuint: 'OutQuint',
  InOutQuint: 'InOutQuint',
  InExpo: 'InExpo',
  OutExpo: 'OutExpo',
  InOutExpo: 'InOutExpo',
  InCirc: 'InCirc',
  OutCirc: 'OutCirc',
  InOutCirc: 'InOutCirc',
  InBack: 'InBack',
  OutBack: 'OutBack',
  InOutBack: 'InOutBack',
  InElastic: 'InElastic',
  OutElastic: 'OutElastic',
  InOutElastic: 'InOutElastic',
  InBounce: 'InBounce',
  OutBounce: 'OutBounce',
  InOutBounce: 'InOutBounce',
} as const;

const c1 = 1.70158;
const c2 = c1 * 1.525;
const c3 = c1 + 1;
const c4 = (2 * Math.PI) / 3;
const c5 = (2 * Math.PI) / 4.5;
const n1 = 7.5625;
const d1 = 2.75;

export class Easing {
  static get(key: string): (x: number) => number {
    return Easing[key as keyof typeof easingProperties] || Easing.Linear;
  }

  static Linear(x: number): number {
    return x;
  }

  static InSine(x: number): number {
    return 1 - Math.cos((x * Math.PI) / 2);
  }

  static OutSine(x: number): number {
    return Math.sin((x * Math.PI) / 2);
  }

  static InOutSine(x: number): number {
    return -(Math.cos(Math.PI * x) - 1) / 2;
  }

  static InQuad(x: number): number {
    return x * x;
  }

  static OutQuad(x: number): number {
    return 1 - (1 - x) * (1 - x);
  }

  static InOutQuad(x: number): number {
    return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
  }

  static InCubic(x: number): number {
    return x * x * x;
  }

  static OutCubic(x: number): number {
    return 1 - Math.pow(1 - x, 3);
  }

  static InOutCubic(x: number): number {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  static InQuart(x: number): number {
    return x * x * x * x;
  }

  static OutQuart(x: number): number {
    return 1 - Math.pow(1 - x, 4);
  }

  static InOutQuart(x: number): number {
    return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
  }

  static InQuint(x: number): number {
    return x * x * x * x * x;
  }

  static OutQuint(x: number): number {
    return 1 - Math.pow(1 - x, 5);
  }

  static InOutQuint(x: number): number {
    return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
  }

  static InExpo(x: number): number {
    return x === 0 ? 0 : Math.pow(2, 10 * x - 10);
  }

  static OutExpo(x: number): number {
    return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
  }

  static InOutExpo(x: number): number {
    return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? Math.pow(2, 20 * x - 10) / 2 : (2 - Math.pow(2, -20 * x + 10)) / 2;
  }

  static InCirc(x: number): number {
    return 1 - Math.sqrt(1 - Math.pow(x, 2));
  }

  static OutCirc(x: number): number {
    return Math.sqrt(1 - Math.pow(x - 1, 2));
  }

  static InOutCirc(x: number): number {
    return x < 0.5 ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2 : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
  }

  static InBack(x: number): number {
    return c3 * x * x * x - c1 * x * x;
  }

  static OutBack(x: number): number {
    return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
  }

  static InOutBack(x: number): number {
    return x < 0.5
      ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
      : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
  }

  static InElastic(x: number): number {
    return x === 0 ? 0 : x === 1 ? 1 : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4);
  }

  static OutElastic(x: number): number {
    return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
  }

  static InOutElastic(x: number): number {
    return x === 0
      ? 0
      : x === 1
      ? 1
      : x < 0.5
      ? -(Math.pow(2, 20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2
      : (Math.pow(2, -20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1;
  }

  static InBounce(x: number): number {
    return 1 - Easing.OutBounce(1 - x);
  }

  static OutBounce(x: number): number {
    if (x < 1 / d1) {
      return n1 * x * x;
    } else if (x < 2 / d1) {
      return n1 * (x -= 1.5 / d1) * x + 0.75;
    } else if (x < 2.5 / d1) {
      return n1 * (x -= 2.25 / d1) * x + 0.9375;
    } else {
      return n1 * (x -= 2.625 / d1) * x + 0.984375;
    }
  }

  static InOutBounce(x: number): number {
    return x < 0.5 ? (1 - Easing.OutBounce(1 - 2 * x)) / 2 : (1 + Easing.OutBounce(2 * x - 1)) / 2;
  }
}
