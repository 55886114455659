import { ISongs } from '../../config';
import {
  BIG_WIN_AMOUNT_LIMIT,
  BigWinStages,
  GREAT_WIN_AMOUNT_LIMIT,
  MEGA_WIN_AMOUNT_LIMIT,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  WinStages,
} from '../config';

export const WIN_TITLE_Y = SLOTS_CONTAINER_HEIGHT / 2;
export const WIN_TITLE_X = SLOTS_CONTAINER_WIDTH / 2;

export const WIN_LABEL_Y = SLOTS_CONTAINER_HEIGHT / 2;
export const WIN_LABEL_X = SLOTS_CONTAINER_WIDTH / 2;

export const HAMMER_ANIMATION_REEL_DELAY = 100;
export const HAMMER_ANIMATION_DURATION = 1000;

export const BIG_WIN_JINGLE_DURATION = 3000;

export const bigWinAnimationNames: Record<BigWinStages, { in: string; loop: string; out: string }> = {
  [WinStages.BigWin]: {
    in: 'bigwin_in',
    loop: 'bigwin_loop',
    out: 'bigwin_out',
  },
  [WinStages.MegaWin]: {
    in: 'megawin_in',
    loop: 'megawin_loop',
    out: 'megawin_out',
  },
  [WinStages.GreatWin]: {
    in: 'greatwin_in',
    loop: 'greatwin_loop',
    out: 'greatwin_out',
  },
  [WinStages.EpicWin]: {
    in: 'epicwin_in',
    loop: 'epicwin_loop',
    out: 'epicwin_out',
  },
};

export const bigWinsConfig: Record<
  BigWinStages,
  { beginMultiplier: number; limitAmount: number; duration: number; song: ISongs }
> = {
  [WinStages.BigWin]: {
    beginMultiplier: 0,
    limitAmount: BIG_WIN_AMOUNT_LIMIT,
    duration: 4000,
    song: ISongs.SONG_SFX_Win_Big,
  },
  [WinStages.MegaWin]: {
    beginMultiplier: BIG_WIN_AMOUNT_LIMIT,
    limitAmount: MEGA_WIN_AMOUNT_LIMIT,
    duration: 4000,
    song: ISongs.SONG_SFX_Win_Mega,
  },
  [WinStages.GreatWin]: {
    beginMultiplier: MEGA_WIN_AMOUNT_LIMIT,
    limitAmount: GREAT_WIN_AMOUNT_LIMIT,
    duration: 4000,
    song: ISongs.SONG_SFX_Win_Great,
  },
  [WinStages.EpicWin]: {
    beginMultiplier: GREAT_WIN_AMOUNT_LIMIT,
    limitAmount: Number.POSITIVE_INFINITY,
    duration: 4000,
    song: ISongs.SONG_SFX_Win_Epic,
  },
};
