import { Application } from 'pixi.js';

import { Maker } from './maker/maker';

export class Game {
  private static _instance: Game;
  public readonly app: Application;
  public readonly maker: Maker;

  public static getInstance = (): Game => Game._instance;

  private constructor() {
    this.app = this.makeApplication();
    this.maker = new Maker(this.app);
  }

  public static init() {
    Game._instance = new Game();
  }

  private makeApplication() {
    const application = new Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
    });

    return application;
  }
}
