import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const PayLineDescriptions: React.VFC = () => {
  const { t } = useTranslation();
  const descriptions: string[] = t('infoPayLinesDesc', {
    returnObjects: true,
  });

  return (
    <div className={styles.col}>
      {descriptions.map((v) => (
        <p className={`${styles.p}`} key={v} dangerouslySetInnerHTML={{ __html: v }} />
      ))}
    </div>
  );
};

const PayLines: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles.payLines}>
        <h1 className={styles.title}>{t('infoPayLinesTitle')}</h1>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoPaylines)} alt="payLines" />
        <PayLineDescriptions />
      </div>
    </div>
  );
};

export default PayLines;
