import { Container } from 'pixi.js';

import { Game } from '../../game';
import { EventTypes, GameMode } from '../../global.d';
import { StrictSpine } from '../components/spine';
import { eventManager } from '../config';

export class Ambient extends Container {
  private spine: StrictSpine<'ambient'>;

  constructor() {
    super();
    this.spine = Game.getInstance().maker.spine('ambient');

    this.addChild(this.spine);

    this.onChangeMode(GameMode.BASE_GAME);

    eventManager.addListener(EventTypes.CHANGE_MODE, (settings: { mode: GameMode }) =>
      this.onChangeMode(settings.mode),
    );
  }

  private onChangeMode(mode: GameMode) {
    this.spine.state.setAnimation(0, mode === GameMode.FREE_SPINS ? 'featuregame' : 'basegame', true);
  }
}
