import { Container, DisplayObject, Graphics } from 'pixi.js';

import { Game } from '../../game';
import { EventTypes } from '../../global.d';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH } from '../background/config';
import { StrictSpine } from '../components/spine';
import { eventManager } from '../config';

import { SCENE_CHANGE_BACKGROUND_COLOR } from './config';

class SceneChange extends Container {
  private rect: Graphics;

  private spider: StrictSpine<'stagechange'>;

  private windowSize = { width: 0, height: 0, scale: 1 };

  constructor() {
    super();
    this.visible = false;

    this.rect = this.initRect();
    this.addChild(this.rect);

    const spider = Game.getInstance().maker.spine('stagechange');
    this.spider = spider;
    this.addChild(spider);

    eventManager.addListener(EventTypes.SCENE_CHANGE_DOWN, this.startChangeSceneAnimationToFreeSpin.bind(this));
    eventManager.addListener(EventTypes.SCENE_CHANGE_UP, this.startChangeSceneAnimationToBaseGame.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, (_width, _height, _x, _y, scale, _pivotX, _pivotY) => {
      this.windowSize.scale = scale;
    });
  }

  private initRect() {
    const rect = new Graphics();
    rect.beginFill(SCENE_CHANGE_BACKGROUND_COLOR);
    rect.drawRect(0, 0, BACKGROUND_SIZE_WIDTH, BACKGROUND_SIZE_HEIGHT);

    return rect;
  }

  private getFadeAnimation(object: DisplayObject, duration: number, begin: number, target: number) {
    const animation = new Tween({
      object: object,
      duration,
      property: TweenProperties.ALPHA,
      propertyBeginValue: begin,
      target: target,
    });
    return animation;
  }

  private setSpiderParam() {
    this.spider.alpha = 1;
    this.spider.scale.x = this.windowSize.scale;
    this.spider.scale.y = this.windowSize.scale;
    this.spider.position.y = this.windowSize.height / 2;
    this.spider.position.x = this.windowSize.width / 2;
  }

  private startChangeSceneAnimationToFreeSpin(callbackFunction?: () => void) {
    const animationChain = new AnimationChain();
    const rectStartY = this.windowSize.height - 200;

    this.visible = true;

    const inAnimationGroup = new AnimationGroup();
    this.setSpiderParam();
    const inSpider = this.spider.getAnimation(0, 'bg_stagechange');
    inAnimationGroup.addAnimation(inSpider);

    this.rect.alpha = 0;
    this.rect.y = 0;
    this.rect.height = this.windowSize.height + this.spider.height;

    const upRect = new Tween({
      object: this.rect,
      duration: 10 * 33,
      property: TweenProperties.Y,
      propertyBeginValue: rectStartY,
      target: 0,
    });
    const delay = Tween.createDelayAnimation(40 * 33);
    delay.addOnComplete(() => {
      this.rect.alpha = 1;
      upRect.start();
    });
    inAnimationGroup.addAnimation(delay);

    const fadeOutRect = this.getFadeAnimation(this.rect, 1000, 1, 0);
    if (callbackFunction) {
      fadeOutRect.addOnStart(() => callbackFunction());
    }

    animationChain.appendAnimation(inAnimationGroup);
    animationChain.appendAnimation(Tween.createDelayAnimation(500));
    animationChain.appendAnimation(fadeOutRect);

    animationChain.addOnComplete(() => {
      this.visible = false;
    });

    animationChain.start();
  }

  private startChangeSceneAnimationToBaseGame(callback?: () => void) {
    const rectTargetY = this.windowSize.height;

    this.visible = true;
    this.setSpiderParam();

    const animationGroup = new AnimationGroup();
    const inSpider = this.spider.getAnimation(0, 'fg_stagechange');

    const rectAnimationChain = new AnimationChain();
    this.rect.alpha = 1;
    this.rect.height = this.windowSize.height + this.spider.height;
    const downRect = new Tween({
      object: this.rect,
      duration: 10 * 33,
      property: TweenProperties.Y,
      propertyBeginValue: -this.rect.height,
      target: rectTargetY - this.rect.height,
    });
    const fadeOutRect = this.getFadeAnimation(this.rect, 500, 1, 0);

    rectAnimationChain.appendAnimation(downRect);
    rectAnimationChain.appendAnimation(Tween.createDelayAnimation(20 * 33));
    rectAnimationChain.appendAnimation(fadeOutRect);
    if (callback) {
      fadeOutRect.addOnStart(() => callback());
    }

    animationGroup.addAnimation(inSpider);
    animationGroup.addAnimation(rectAnimationChain);
    animationGroup.addOnComplete(() => {
      this.visible = false;
    });

    animationGroup.start();
  }

  private resize(width: number, height: number): void {
    this.windowSize.width = width;
    this.windowSize.height = height;
  }
}
export default SceneChange;
