import { upgradeConfig } from '@pixi/particle-emitter';

import { GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';

import { BackGroundSkin } from './background';

export const BACKGROUND_SIZE_WIDTH = 1920;
export const BACKGROUND_SIZE_HEIGHT = 1080;
export const BACKGROUND_BASE_GAME_GRADIENT = ['#3C466E', '#5787B8', '#6E96BE', '#FFFFFF'];
export const BACKGROUND_FREE_SPINS_GRADIENT = ['#2b2b2b', '#606060', '#585858', '#f0f0f0'];

export const backGroundGameModeSkins: Record<GameMode, BackGroundSkin> = {
  [GameMode.BASE_GAME]: 'base',
  [GameMode.BUY_FEATURE]: 'base',
  [GameMode.FREE_SPINS]: 'freeSpin',
};

export const particleConfig = upgradeConfig(
  {
    alpha: {
      start: 0.9,
      end: 0.01,
    },
    scale: {
      start: 0.6,
      end: 1.0,
      minimumScaleMultiplier: 0.2,
    },
    color: {
      start: '#ffffff',
      end: '#ffc400',
    },
    speed: {
      start: 1,
      end: 0,
      minimumSpeedMultiplier: 0.8,
    },
    acceleration: {
      x: 0,
      y: -1,
    },
    maxSpeed: 200,
    startRotation: {
      min: -15,
      max: 45,
    },
    noRotation: false,
    rotationSpeed: {
      min: 0,
      max: 0.1,
    },
    lifetime: {
      min: 0.5,
      max: 4,
    },
    blendMode: 'add',
    frequency: 0.04,
    emitterLifetime: -1,
    maxParticles: 80,
    pos: {
      x: 0,
      y: -5,
    },
    addAtBack: false,
    spawnType: 'rect',
    spawnRect: {
      x: -500,
      y: -1000,
      w: 2500,
      h: 2000,
    },
  },
  [ResourceTypes.particle, ResourceTypes.sparks],
);
