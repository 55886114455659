import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';

export interface ICascadeAnimation {
  disappearingAnimation: Animation;
  waitingAnimation: Animation;
  appearingAnimation?: Animation;
}

export class CascadeAnimation extends AnimationChain {
  constructor(option: ICascadeAnimation) {
    super();
    this.appendAnimation(option.disappearingAnimation);
    this.appendAnimation(option.waitingAnimation);
    option.appearingAnimation && this.appendAnimation(option.appearingAnimation);
  }

  public getDisappearing(): Animation {
    return this.animations[0]!;
  }

  public getWaiting(): Animation {
    return this.animations[1]!;
  }

  public getAppearing(): Animation {
    return this.animations[2]!;
  }
}
