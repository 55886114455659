import _ from 'lodash';

import { ReelSet } from '../global';
import { REELS_AMOUNT } from '../slotMachine/config';
import { Icon } from '../slotMachine/d';

import { getIconById } from './helper';

export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
  slotPerReelAmount,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
  slotPerReelAmount: number;
}): Icon[] => {
  const spinResult = [];
  spinResult.push(
    ...reelPositions.map((random, index) => {
      const prevRandom = random - 1 < 0 ? reelSet.layout[index]!.length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index]![prevRandom]!);
    }),
  );

  // base position
  spinResult.push(
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index]![random]!);
    }),
  );

  for (let i = 2; i < slotPerReelAmount; i++) {
    spinResult.push(
      ...reelPositions.map((random, index) => {
        const nextRandom = (random + i - 1) % reelSet.layout[index]!.length;
        return getIconById(icons, reelSet.layout[index]![nextRandom]!);
      }),
    );
  }

  return spinResult;
};

export const getSymbolMatrixFromIcons = (icons: Icon[]) => {
  const symbolMatrix = _(icons)
    .chunk(REELS_AMOUNT)
    .unzip()
    .value()
    .map((icons) => icons.map((icon) => icon.id));
  return symbolMatrix;
};

export const getSymbolMatrixFromSymbols = <T>(symbols: T[]) => {
  const symbolMatrix = _(symbols).chunk(REELS_AMOUNT).unzip().value();
  return symbolMatrix;
};
