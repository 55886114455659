import type { Howl } from 'howler';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, audioSpriteVolume } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setGameMode } from '../../gql';
import { isFreeSpinMode, wait } from '../../utils';
import { SlotMachineState, eventManager } from '../config';

declare module 'howler' {
  interface Howl {
    _soundById(id: number): Sound;
  }
  interface Sound {
    _start: number;
    _node: { bufferSource: AudioBufferSourceNode };
  }
}

enum BgmSoundType {
  BASE = 'base',
  FREE_SPIN = 'freeSpin',
}
type BgmIndexType = { type: BgmSoundType; song: ISongs; melody?: ISongs };
type BgmType = Record<BgmSoundType, { bgms: ISongs[]; loopStart?: number }>;
type BgmPlayInfo = { song: ISongs; id: number };
type BgmControlType = Record<BgmSoundType, BgmPlayInfo[]>;

export const bgTypeToBgmSoundType: Record<BgmSoundType, BgmIndexType> = {
  base: {
    type: BgmSoundType.BASE,
    song: ISongs.SONG_032_01_BaseGameBGM_Base,
    melody: ISongs.SONG_032_02_BaseGameBGM_Melo,
  },
  freeSpin: { type: BgmSoundType.FREE_SPIN, song: ISongs.SONG_032_03_FreeSpinBGM },
};

export const bgmList: BgmType = {
  base: { bgms: [ISongs.SONG_032_01_BaseGameBGM_Base, ISongs.SONG_032_02_BaseGameBGM_Melo] },
  freeSpin: { bgms: [ISongs.SONG_032_03_FreeSpinBGM] },
};

class BgmControl {
  private bgmListIndex: BgmIndexType;

  private bgmPlayInfo: BgmControlType;

  private playingMelody = false;

  private restrictedHandler: ((restricted: boolean) => void) | undefined;

  private timer: NodeJS.Timeout | undefined;

  private get howl(): Howl {
    return AudioApi['audioHowl'];
  }

  constructor() {
    this.bgmListIndex = bgTypeToBgmSoundType[BgmSoundType.BASE];
    this.bgmPlayInfo = {
      base: [],
      freeSpin: [],
    };
    this.restrictedHandler = undefined;
    AudioApi.on('restricted', (restricted: boolean) => {
      if (this.restrictedHandler) {
        this.restrictedHandler(restricted);
      }
    });

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
  }

  private bgmPlayWait = (id: number): Promise<void> => {
    return new Promise((resolve) => {
      this.howl.once('play', () => resolve(), id);
    });
  };

  private waitUnrestricted(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!this.restrictedHandler) {
        this.restrictedHandler = (restricted: boolean) => {
          if (!restricted) {
            resolve();
            this.restrictedHandler = undefined;
          }
        };
      }
    });
  }

  private async initBgm(): Promise<void> {
    // In fact, all background music is played here.
    for (const [key, obj] of Object.entries(bgmList)) {
      const soundType = key as BgmSoundType;
      const loopStart = obj.loopStart;

      if (AudioApi.isRestricted) await this.waitUnrestricted();

      this.bgmPlayInfo[soundType] = await Promise.all(
        obj.bgms.map(async (song) => {
          const id = AudioApi.play({ type: song, volume: 0 });
          await this.bgmPlayWait(id);
          if (loopStart) {
            this.setLoopBgm(id, loopStart);
          }
          return { song, id };
        }),
      );
    }
  }

  private onChangeMode(settings: { mode: GameMode }) {
    if (setBrokenGame()) {
      return;
    }

    const bgmSoundType = settings.mode === GameMode.FREE_SPINS ? BgmSoundType.FREE_SPIN : BgmSoundType.BASE;
    const bgmListIndex = bgTypeToBgmSoundType[bgmSoundType];
    const fromStartConditions = [
      this.bgmListIndex.type === BgmSoundType.BASE && bgmListIndex.type === BgmSoundType.FREE_SPIN,
      this.bgmListIndex.type === BgmSoundType.FREE_SPIN && bgmListIndex.type === BgmSoundType.BASE,
    ];

    const fromStart = fromStartConditions.some((condition) => condition === true);
    this.playBgm(bgmSoundType, fromStart, fromStart);
  }

  private clearTimeout() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  private onSlotMachineStateChange(state: SlotMachineState) {
    if (state === SlotMachineState.IDLE) {
      this.clearTimeout();

      if (this.playingMelody) {
        this.timer = setTimeout(() => {
          this.fadeOutMelody(3000);
        }, 30 * 1000);
      }
    } else if (state === SlotMachineState.SPIN) {
      this.clearTimeout();
    }
  }

  private resetVolume(song: ISongs): void {
    const volume = audioSpriteVolume[song] || 0.3; // TO DO
    AudioApi.setTrackVolume(song, volume);
  }

  private resumeBgm(bgmListIndex: BgmIndexType) {
    this.bgmPlayInfo[bgmListIndex.type].forEach((info) => {
      if (AudioApi.getSoundByKey(bgmListIndex.song).id === info.id) {
        this.resetVolume(info.song);
      }
    });
  }

  private seekBgm(bgmId: number, seekPoint: number): void {
    const sound = this.howl['_soundById'](bgmId);
    this.howl.seek((sound['_start'] as number) + seekPoint / 1000, bgmId);
  }

  private setLoopBgm(bgmId: number, loopStart: number): void {
    const sound = this.howl['_soundById'](bgmId);
    const node = sound['_node'];
    if (node.bufferSource) {
      node.bufferSource.loopStart = (sound['_start'] as number) + loopStart / 1000;
    }
  }

  private reStartBgm(bgmListIndex: BgmIndexType, fromStart?: boolean): void {
    const loopStart = bgmList[bgmListIndex.type]!.loopStart ?? 0;
    const seekPoint = fromStart === true ? 0 : loopStart;

    this.bgmPlayInfo[bgmListIndex.type].forEach((info) => {
      this.seekBgm(info.id, seekPoint);
      this.setLoopBgm(info.id, loopStart); // loopstart is reset by seek
    });
    this.resumeBgm(bgmListIndex);
  }

  private stopAllExceptIndex(bgmListIndex: BgmIndexType): void {
    if (AudioApi.isRestricted) {
      return;
    }

    Object.values(this.bgmPlayInfo).forEach((infos) => {
      infos.forEach((info) => {
        if (info.id && AudioApi.getSoundByKey(bgmListIndex.song).id != info.id) {
          AudioApi.setTrackVolume(info.song, 0);
        }
      });
    });
  }

  public fadeInBase(fadeTime: number): void {
    const bgmName = this.bgmListIndex.song as ISongs;
    AudioApi.fadeIn(fadeTime, bgmName, audioSpriteVolume[bgmName]);
  }

  public playBgm(bgmType?: BgmSoundType, forceRestart = false, fromStart = false) {
    if (AudioApi.isRestricted) {
      return;
    }
    const bgmListIndex = bgmType ? bgTypeToBgmSoundType[bgmType] : this.bgmListIndex;
    const shouldRestart = this.bgmListIndex.type !== bgmListIndex.type || forceRestart;
    const shouldResume = this.bgmListIndex.type === bgmListIndex.type && this.bgmListIndex.song !== bgmListIndex.song;

    if (shouldRestart || shouldResume) {
      this.stopAllExceptIndex(bgmListIndex);
      this.bgmListIndex = bgmListIndex;
    }

    // In fact, it is not starting playback, it is just turning the volume back up.
    if (shouldRestart) {
      this.reStartBgm(bgmListIndex, fromStart);
    } else {
      this.resumeBgm(bgmListIndex);
    }
  }

  public stopAll() {
    if (AudioApi.isRestricted) {
      return;
    }

    // In fact, it is not stopped, just the volume is set to zero.
    Object.values(this.bgmPlayInfo).forEach((infos) => {
      infos.forEach((info) => {
        AudioApi.setTrackVolume(info.song, 0);
      });
    });
  }

  public fadeInAll(fadeTime: number) {
    this.fadeInBase(fadeTime);
  }

  public fadeOutAll(fadeTime: number) {
    AudioApi.fadeOut(fadeTime, this.bgmListIndex.song);
    this.fadeOutMelody(fadeTime);
  }

  public fadeInMelody(fadeTime: number) {
    if (bgTypeToBgmSoundType[this.bgmListIndex.type].melody) {
      const melody = bgTypeToBgmSoundType[this.bgmListIndex.type].melody!;
      const soundProp = AudioApi.getSoundByKey(melody);
      if (soundProp.volume !== 0) {
        return;
      }

      this.playingMelody = true;
      AudioApi.fadeIn(fadeTime, melody, audioSpriteVolume[melody]);
    }
  }

  public fadeOutMelody(fadeTime: number): void {
    if (bgTypeToBgmSoundType[this.bgmListIndex.type].melody) {
      const melody = bgTypeToBgmSoundType[this.bgmListIndex.type].melody!;
      this.playingMelody = false;
      AudioApi.fadeOut(fadeTime, melody);
    }
  }

  public async handleChangeRestriction(): Promise<void> {
    await this.initBgm();
    await wait(10);

    const bgmSoundType = setBrokenGame() || isFreeSpinMode(setGameMode()) ? BgmSoundType.FREE_SPIN : BgmSoundType.BASE;
    this.playBgm(bgmSoundType, true, true);
  }
}

const bgmControl = new BgmControl();

export { bgmControl as BgmControl };
export default bgmControl;
